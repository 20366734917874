html {
  position: relative;
  height: 100%;
  font-size: $font-size__base;
  @include breakpoint($tablet) {
    font-size: $font-size__base - 1px;
  }
  @include breakpoint($mobile) {
    font-size: $font-size__base - 2px;
  }
}
body {
  //min-width: 1200px;
  height: 100%;
  color: $color-text__base;
  font-size: $font-size__base;
  font-family: $font-family__base;
  @include breakpoint($desktop) {
    min-width: $tablet2;
  }
  @include breakpoint($tablet2) {
    min-width: $tablet;
  }
  @include breakpoint($tablet) {
    min-width: $mobile;
  }
  @include breakpoint($mobile) {
    min-width: 100%;
  }
}
.hidden {
  display: none;
}
.clearfix {
  @include clearfix();
}
.wrapper {
  position: relative;
  margin: 0 auto;
  //width: 1200px;
  @include clearfix();
  //@include breakpoint($desktop) {
  //  width: $tablet2;
  //}
  //@include breakpoint($tablet2) {
  //  width: $tablet;
  //}
  //@include breakpoint($tablet) {
  //  width: $mobile;
  //}
  //@include breakpoint($mobile) {
  //  width: 100%;
  //}
}

.preloader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  background: #133266 50% 67% no-repeat url("/assets/img/preloader_night_bg.jpg");
  color: #fff;
  font-family: sans-serif;
  &__spinner {
    @include center(150px, 150px);
  }
  &__animation {
    @include center(75px, 75px);
  }
  &__percents {
    @include center();
  }
  &-svg__spinner {
    transition: all 2s ease-out;
    animation: preloader__spinner 4s ease-in-out infinite;
    stroke-linecap: round;
    stroke-dasharray: 0, 35%;
    stroke-dashoffset: -277%;
  }
  &-svg__percent {
    opacity: 0.05;
    transition: all 2s ease-out;
  }
}
@keyframes preloader__spinner {
  0% {
    stroke-dasharray: 0, 35%;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 0, 277%;
  }
  100% {
    stroke-dasharray: 0, 35%;
    stroke-dashoffset: -277%;
  }
}

a {
  transition: 0.4s;
}
p {
  margin: .7em 0 .7em;
  line-height: 1.5;
}
.left-side {
  float: left;
}
.right-side {
  float: right;
}
.column2 {
  float: left;
  box-sizing: border-box;
  width: 50%;
  @include breakpoint($tablet) {
    float: none;
    width: 100%;
  }
}
.column3 {
  float: left;
  box-sizing: border-box;
  width: 33.33%;
  @include breakpoint($mobile) {
    float: none;
    width: 100%;
  }
}

.heading {
  margin: 9px 0;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 400;
  font-size: 63px;
  font-family: $font-family__heading;
  line-height: 66px;
  @include breakpoint($mobile) {
    font-size: 46px;
    line-height: 48px;
  }
  &_medium {
    font-size: 35px;
    line-height: 41px;
    @include breakpoint($mobile) {
      font-size: 32px;
      line-height: 38px;
    }
  }
}
.heading-line {
  margin: 14px 0;
  text-align: center;
  text-transform: uppercase;
  font-size: 35px;
  font-family: $font-family__heading;
  @include breakpoint($mobile) {
    font-weight: 200;
    font-size: 26px;
  }
  &::after {
    display: block;
    margin: 11px auto 0;
    width: 90px;
    border-bottom: 3px solid $color-text__base;
    content: "";
    @include breakpoint($mobile) {
      width: 60px;
    }
  }
  &_after_lighter {
    margin: 28px 0;
    @include breakpoint($mobile) {
      font-size: 35px;
    }
    &::after {
      margin-top: 23px;
      border-color: #878e9e;
      @include breakpoint($mobile) {
        margin-top: 8px;
      }
    }
  }
}
.developer {
  margin: 20px auto;
  max-width: 580px;
  width: 100%;
  color: $color-text__main;
  text-align: center;
  &__svg {
    position: absolute;
    top: 0;
    display: block;
    max-height: 273px;
    width: 92%;
    height: 100%;
    opacity: .07;
  }
  &__photo-wrap {
    overflow: hidden;
    margin: 0 auto 20px;
    width: 141px;
    height: 141px;
    border-radius: 50%;
  }
  &__photo {
    max-width: 100%;
  }
  &__name {
    margin-bottom: 7px;
    font-weight: $font-weight__medium;
    font-size: 35px;
  }
  &-photo {
    margin: 40px 0 27px;
    &__image {
      min-height: 390px;
    }
  }
}

.header{
  position: relative;
  overflow: hidden;
  min-height: 370px;
  height: 100%;
  background: #223b65 50% 60% no-repeat url("/assets/img/header_bg.jpg");
  &__video {
    position: relative;
    height: 100%;
    @include breakpoint($tablet) {
      display: none;
    }
    &-bg-video {
      @include center();
    }
    &-bg {
      position: relative;
      height: 100%;
      background: transparent 50% 60% no-repeat url("/assets/img/header_night_no_bg.png");
    }
  }
  &__wrapper {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }
  &__side-wrap {
    height: 100px;
  }
  &__developer {
    @include center();
    margin: -60px 0 0;
    padding: 35px 0 12px;
    &_compact {
      margin-top: 20px;
    }
  }
  &_fullscreen {
    min-height: 580px;
    &__right-side {
      @include breakpoint($mobile) {
        position: relative;
        float: none;
      }
    }
  }
  &_triangles {
    &::before {
      position: absolute;
      bottom: 0;
      z-index: 1;
      width: 100%;
      height: 169px;
      content: "";
      background: {
        image:
          linear-gradient(-170deg, transparent 166px, $color-bg__col-1 167px),
          linear-gradient(170deg, transparent 166px, $color-bg__col-2 167px);
        position: 0 100%, 100% 100%;
        repeat: no-repeat;
        size: 50% 100%;
      }
      @include breakpoint($tablet) {
        background: {
          image:
            linear-gradient(-170deg, transparent 166px, $color-bg__col-1 167px),
            linear-gradient(170deg, transparent 166px, $color-bg__col-1 167px);
        }
      }
    }
  }
  &_theme_dark {
    min-height: 600px;
    &::before {
      background: {
        image:
          linear-gradient(-170deg, transparent 166px, $color-bg__theme_dark 167px),
          linear-gradient(170deg, transparent 166px, $color-bg__theme_dark 167px) !important;
      }
      bottom: 184px;
      opacity: .85;
    }
    &::after {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 184px;
      background: $color-bg__theme_dark;
      content: "";
      opacity: .85;
    }
  }
  &_compact {
    max-height: 400px;
    background-position-y: 67%;
    &::before {
      background: {
        image:
          linear-gradient(-170deg, transparent 166px, $color-bg__compact 167px),
          linear-gradient(170deg, transparent 166px, $color-bg__compact 167px);
      }
    }
  }
  &-heading {
    position: absolute;
    bottom: -10px;
    z-index: 9;
    width: 100%;
    height: 188px;
    &__heading {
      position: relative;
      top: -133px;
      margin: 0;
      color: $color-text__main;
    }
  }
  &-heading__svg {
    position: relative;
    bottom: 9px;
    display: block;
    margin: 0 auto;
    width: 400px;
    height: 188px;
    color: $color-text__main;
    opacity: .15;
    transform: rotate(189deg);
  }
  &__btn {
    position: relative;
    z-index: 99;
    display: block;
    margin: 20px;
    padding: 10px 25px;
    border: 1px solid $color-text__main;
    color: $color-text__main;
    text-decoration: none;
    transition: 0.4s;
    @include breakpoint($mobile) {
      @include center();
      top: 20px;
    }
    &:hover, &:active, &:focus {
      border: 1px solid rgba(0, 76, 209, 0.5);
      background: rgba(0, 76, 209, 0.5);
    }
  }
}
.socials{
  &__list {
    margin: 18px;
    padding: 0;
    list-style: none;
    @include clearfix();
  }
  &__item {
    float: left;
    margin: 14px;
    max-width: 28px;
    height: 22px;
  }
  &__link {
    color: $color-text__main;
    &:hover, &:active, &:focus {
      color: $color-text__accent;
    }
  }
  &__svg {
    max-width: 100%;
    max-height: 100%;
    transition: 0.4s;
  }
}
.menu {
  position: relative;
  &_active {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9998;
  }
  &__open-link {
    position: relative;
    top: 0;
    right: 0;
    z-index: 9999;
    display: block;
    margin: 28px 31px;
    width: 40px;
    height: 35px;
    color: $color-text__main;
  }
  &__hamburger {
    top: auto;
    bottom: 0;
    transition-delay: 0.15s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition-duration: 0.15s;
    &, &::before, &::after {
      position: absolute;
      width: 40px;
      height: 5px;
      border-radius: 3px;
      background-color: #fff;
      transition-timing-function: ease;
      transition-duration: 0.15s;
      transition-property: transform;
    }
    &::before, &::after {
      display: block;
      content: "";
    }
    &::before {
      top: -13px;
      transition: top 0.12s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    &::after {
      top: -28px;
      bottom: -13px;
      transition: top 0.3s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
    }
  }
  &_active &__hamburger {
    transition-delay: 0.32s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, -10px, 0) rotate(-45deg);
    &::before {
      top: 0;
      transition: top 0.12s 0.18s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.15s 0.42s cubic-bezier(0.215, 0.61, 0.355, 1);
      transform: rotate(-90deg);
    }
    &::after {
      top: 0;
      opacity: 0;
      transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.27s linear;
    }
  }
  &__fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    visibility: hidden;
    margin: 0;
    width: 100%;
    height: 100%;
    background: #183669;
    background: rgba(24,54,105,.87);
    opacity: 0;
    transition: opacity .4s;
    & > .wrapper {
      height: 100%;
    }
  }
  &_active &__fullscreen {
    visibility: visible;
    opacity: 1;
  }
  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: center;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 63px;
    font-family: $font-family__heading;
    line-height: 116px;
    @include vertical-align();
    @include breakpoint($tablet) {
      font-size: 48px;
      line-height: 64px;
    }
  }
  &__item {
    margin: 28px 0;
  }
  &__link {
    display: block;
    color: $color-text__menu;
    text-decoration: none;
    &::after {
      display: block;
      margin: -3px auto 0;
      width: 90px;
      border-bottom: 3px solid rgba($color-text__menu, 0);
      content: "";
      transition: 0.5s;
    }
    &:hover, &:active, &:focus {
      &::after {
        border-bottom: 3px solid $color-text__menu;
      }
    }
  }
}
.main {
  background: linear-gradient(90deg, $color-bg__col-1 50%, $color-bg__col-2 50%);
  @include breakpoint($tablet) {
    background: $color-bg__col-1;
  }
  &-col-1 {
    padding: 0 6% 15px 7%;
    background: $color-bg__col-1;
    @include breakpoint($mobile) {
      padding: 0 2% 15px;
    }
    &-heading-wrap {
      position: relative;
      @include breakpoint($large) {
        float: left;
        width: 40%;
      }
    }
    &__heading {
      margin: 30px 0 55px;
    }
    &-wrap {
      @include breakpoint($large) {
        float: right;
        width: 60%;
      }
    }
    &-heading__svg {
      position: absolute;
      top: -30px;
      left: 50%;
      display: block;
      margin: 0 0 0 -150px;
      width: 300px;
      height: 150px;
      color: $color-text__accent;
      opacity: .10;
      transform: rotate(189deg);
    }
  }
  &-col-2 {
    padding: 15px 3.3%;
    background: $color-bg__col-2;
    @include breakpoint($tablet) {
      padding: 15px 6% 15px 7%;
    }
    @include breakpoint($mobile) {
      padding: 15px 2% 15px;
    }
    &__heading-line {
      margin: 12px 0 36px;
    }
  }
  &_fullscreen {
    position: absolute;
    top: 0;
    min-height: 580px;
    width: 100%;
    height: 100%;
    background: none;
    @include breakpoint($tablet) {
      background: none;
    }
  }
  &_compact {
    background: $color-bg__compact;
  }
  &__developer {
    @include center();
    box-sizing: border-box;
    margin: 20px auto;
    min-width: 250px;
    max-width: 380px;
    width: 100%;
    height: auto;
    @include breakpoint($mobile) {
      width: 94%;
    }
    perspective: 1000px;
    &__flipper {
      padding: 35px 0 0;
      background: rgba(28,31,41,0.5);
    }
  }
}
.flipper {
  transition: 0.6s;
  transform-style: preserve-3d;
  &_active {
    transform: rotateY(180deg);
  }
  &-front,
  &-back {
    backface-visibility: hidden;
  }
  &-front {
  }
  &-back {
    position: absolute;
    top: 0;
    padding: 35px 0 0;
    width: 100%;
    height: 100%;
    transform: rotateY(180deg);
  }
}
.authorization {
  color: $color-text__main;
  text-align: center;
  &__form {
    position: relative;
    margin: 0;
    padding: 0 0 95px;
  }
  &__heading {
    margin: 0 0 38px;
  }
  &__p {
    margin: 15px 0 10px;
  }
  &__label {
    position: relative;
    margin: 0 20px;
    cursor: pointer;
    &_block {
      display: block;
    }
  }
  &__input {
    box-sizing: border-box;
    margin: 0 0 20px;
    padding: 10px 15px 10px 60px;
    width: 100%;
    height: 45px;
    outline: none;
    border: none;
    background: $color-text__main;
    color: $color-text__base;
    &_error {
      outline: 2px solid rgba(255,0,0,.7);
      outline-offset: 3px;
      background: #ffacac;
      transition: .2s;
      &:focus {
        outline-offset: 0;
      }
    }
    &-svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 45px;
      height: 45px;
      background: #f0efe9;
    }
  }
  &__svg {
    @include center(16px,16px);
    color: #c4cbcd;
    &_file-check {
      display: none;
      margin: -5px 0 0 -5px;
      width: 11px;
      height: 11px;
    }
  }
  &__chk-wrap,
  &__radio-wrap {
    position: relative;
    display: inline-block;
    margin: 0 10px;
    width: 20px;
    height: 20px;
    vertical-align: sub;
  }
  &__chk-wrap {
    border-radius: 2px;
    background: #ffffff;
  }
  &__radio-wrap {
    border-radius: 50%;
    background: #fff;
    &::before {
      position: absolute;
      top: 6px;
      left: 6px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #0548be;
      content: "";
      opacity: 0;
    }
  }
  &__checkbox,
  &__radio {
    display: none;
  }
  &__checkbox:checked + &__chk-wrap {
    background: #0548be;
    .authorization__svg_file-check {
      display: block;
    }
  }
  &__radio:checked + &__radio-wrap::before {
    opacity: 1;
  }
  &__btn {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 50%;
    height: 45px;
    outline: none;
    border: none;
    background: $color-text__accent;
    color: $color-text__main;
    cursor: pointer;
    &:hover {
      background: $color-text__accent2;
    }
    &_link {
      box-sizing: border-box;
      padding: 14px 0;
      text-decoration: none;
    }
    &_submit {
      right: 0;
      left: auto;
      border-left: 1px $color-text__menu solid;
    }
  }
}
.index {
  &-socials {
    margin: 18px auto 10px;
    width: 204px;
    @include clearfix();
  }
  &-menu {
    &__list {
      //position: absolute;
      //bottom: 0;
      display: table;
      margin: 0;
      padding: 0;
      width: 100%;
      list-style: none;
      text-align: center;
      font-weight: $font-weight__medium;
      line-height: 36px;
      @include clearfix();
    }
    &_closed &__fullscreen {
      display: none;
    }
    &__item {
      display: table-cell;
      margin: 0;
      border-right: 1px $color-text__menu solid;
      &:last-of-type {
        border-right: none;
      }
    }
    &__link {
      display: block;
      padding: 7px 0;
      background: rgba(0,76,209,.85);
      color: $color-text__menu;
      text-decoration: none;
      transition: 0.4s;
      &:hover, &:active, &:focus {
        background: rgba(4,62,163,.85);
      }
    }
  }
}
.blog {
  &-col-1 {
    padding: 100px 8% 0 0;
    width: 33%;
    @include clearfix();
    @include breakpoint($tablet) {
      position: fixed;
      top: 0;
      left: -285px;
      z-index: 99;
      padding: 0 18px 0 0;
      width: 285px;
      height: 100%;
      background: $color-text__accent2;
      transition: 0.5s;
      &_active {
        left: 0;
        box-shadow: 3px 0 10px 0 rgba(0,0,0,0.5);
      }
      &::before {
        right: -12px;
        display: block;
        @include vertical-align(absolute);
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background: inherit;
        content: '';
        cursor: pointer;
      }
    }
    &_fixed {
      position: fixed;
      top: 0;
      //min-width: 280px;
      @include breakpoint($tablet) {
        //width: 320px;
      }
    }
  }
  &-col-2 {
    float: right;
    padding: 100px 0 0;
    max-width: 880px;
    width: 66%;
  }
  &-menu {
    float: right;
    max-width: 280px;
    @include breakpoint($tablet) {
      @include vertical-align();
    }
    &__list {
      margin: 20px 0 0;
      padding: 0;
      color: $color-text__blog-menu;
      list-style: none;
      text-align: right;
      line-height: 26px;
      @include breakpoint($tablet) {
        margin: 0;
        color: $color-text__main;
        font-size: 15px;
      }
    }
    &__item {
      margin: 0;
    }
    &__link {
      display: block;
      margin: 10px 0;
      padding: 5px 15px;
      border-right: 3px transparent solid;
      color: $color-text__blog-menu;
      text-decoration: none;
      transition: 0.6s;
      @include breakpoint($tablet) {
        border: none;
        color: $color-text__main;
      }
      &_active,
      &:hover, &:active, &:focus {
        border-right: 3px $color-text__accent2 solid;
        color: $color-text__accent2;
        font-weight: $font-weight__bold;
        @include breakpoint($tablet) {
          border: none;
          color: $color-text__main;
        }
      }
    }
  }
}
.article {
  margin: 0 0 50px;
  @include breakpoint($tablet) {
    margin: 0 auto 50px;
    width: 96%;
  }
  &__heading {
    margin: 0 0 15px;
    color: #455a64;
    text-align: left;
    font-size: 35px;
    line-height: 41px;
    @include breakpoint($mobile) {
      font-size: 30px;
      line-height: 34px;
    }
  }
  &__date {
    margin: 0 0 20px;
    color: rgba(8,60,150,0.7);
  }
}