@include breakpoint-set('default feature', max-width);

$mobile: 480px;
$tablet: 768px;
$tablet2: 992px;
$desktop: 1200px; //1024px;
//$desktophd: "min-width : 1380px";
$large: min-width 1800px;

$font-size__base: 16px;
$font-family__base: "Roboto", sans-serif;
$font-family__heading: "SansusWebissimo", sans-serif;
$font-weight__light: 300;
$font-weight__medium: 600;
$font-weight__bold: 800;

$color-bg__col-1: #f2f0e8;
$color-bg__col-2: #f4f5f0;
$color-bg__compact: #faf8f0;
$color-bg__theme_dark: #0d1120;

$color-text__base: #000;
$color-text__main: #fff;
$color-text__menu: $color-text__main;
$color-text__accent: #004cd1;
$color-text__accent2: #083c96;
$color-text__blog-menu: #566358;